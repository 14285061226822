<!-- eslint-disable vue/no-v-html -->
<template>
  <b-card>
    <b-overlay
      :show="!users"
      rounded="sm"
    >
      <div class="users-table">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="users"
          :columns="columns"
          :rows="users"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: User -->
            <span v-if="props.column.field === 'user'">
              <router-link :to="`/user/details/${props.row.uid}`">{{ props.row.forename + ' ' + props.row.surname }}</router-link>
            </span>

            <!-- Column: subdomain -->
            <span v-else-if="props.column.field === 'subdomain'">
              {{ props.row.subdomain ? props.row.subdomain : 'N/A' }}
            </span>

            <!-- Column: Domain -->
            <span v-else-if="props.column.field === 'domain'">
              {{ props.row.domain ? props.row.domain : 'N/A' }}
            </span>

            <!-- Column: Subscribed -->
            <span
              v-else-if="props.column.field === 'subscribed'"
              v-html="getStartedDate( props.row)"
            />

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','150']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BCard, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import moment from 'moment'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    BCard,
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: 'User',
          field: 'user',
          sortable: true,
          sortFn: this.sortFnUser,
        },
        {
          label: 'Started Date',
          field: 'subscribed',
          sortable: true,
          sortFn: this.sortFnSubscribedDate,
        },
        {
          label: 'Email',
          field: 'email',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    users() {
      return this.$store.state.users.leadsUsers
    },
  },
  created() {
    this.fetchAllUsers()
  },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    fetchAllUsers() {
      this.$store.dispatch('users/fetchLeadsUsers')
        .catch(error => { console.log(error) })
    },
    getStartedDate(user) {
      if (user.sub_start) return `Subscribed on:<br>${this.formatDate(user.sub_start)}`

      if (user.trial_start) return `Trial started on:<br>${this.formatDate(user.trial_start)}`

      return `Registered on:<br>${this.formatDate(user.created_at)}`
    },
    sortFnUser(x, y, col, rowX, rowY) { // eslint-disable-line

      let value1 = `${rowX.forename} ${rowX.surname}`
      let value2 = `${rowY.forename} ${rowY.surname}`

      value1 = this.capitalizeFirstLetter(value1)
      value2 = this.capitalizeFirstLetter(value2)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    sortFnSubscribedDate(x, y, col, rowX, rowY) { // eslint-disable-line
      const date1 = this.getStartedDateForSort(rowX)
      const date2 = this.getStartedDateForSort(rowY)
      const value1 = Date.parse(this.filterFormatDate(date1))// get x
      const value2 = Date.parse(this.filterFormatDate(date2))// get y

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    getStartedDateForSort(user) {
      if (user.sub_start) return user.sub_start
      if (user.trial_start) return user.trial_start
      return user.created_at
    },
    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.users-table {
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}

.dark-layout a {
  color: #fb50a1;
}
</style>
